import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Layout, Container } from "../components";

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
`;

const NotFoundPage = ({ location }) => (
  <Layout pathname={location.pathname}>
    <Container type="text">
      <Title>About</Title>
      <p>
        Beverley Hood is an Edinburgh based artist, born in Darlington, England.
        She trained in Sculpture and Electronic Imaging at Duncan of Jordanstone
        College of Art, Dundee and Nova Scotia College of Art & Design, Canada.
      </p>
      <p>
        Since the mid 1990’s she has interrogated the impact of technology on the
        body, relationships and human experience through the creation of digital
        media and performance arts projects, and writing. She has worked
        collaboratively on numerous occasions, developing projects involving a
        range of practitioners, including medical researchers, scientists,
        writers, technologists, dancers, actors and composers.
      </p>
      <p>
        Her projects have been performed, screened and exhibited at leading
        international venues and events, including: Stockholm Kulturhuser; V & A
        Digital Futures; Talbot Rice Gallery; Edinburgh Art Festival; LifeSpace
        - Science Art Research Gallery; ISEA; Edinburgh International Festival;
        CCA Glasgow; Institute of Contemporary Art, London; Cornerhouse,
        Manchester; and it was shortlisted for the New Technological Art Award
        2014 - Update_5, at the Zebrastraat Museum, Ghent, Belgium. She has
        given numerous talks about her creative practice to a range of
        organisations and audiences in the UK, across Europe and beyond, from
        the USA, to China, Australia, and the United Arab Emirates.
      </p>
      <p>
        Beverley is a Reader in Technological Embodiment and Creative Practice,
        Director of Research and Unit of Assessment REF Coordinator for Design,
        at Edinburgh College of Art, University of Edinburgh, working with
        colleagues from diverse disciplines including Design Informatics,
        Performance Costume, Film, Graphic Design and Jewellery. Her teaching
        responsibilities includes students across the Design, ECA and School of
        Health and Social Science disciplines from undergraduates,
        postgraduates, through to PhD candidates.
      </p>
      <p>
        She is a member of the RAFT(materiality and digital technologies)
        research group at ECA and the Centre for Creative - Relational Inquiry
        at the University of Edinburgh, which fosters innovative ‘creative -
        relational’ qualitative research.
      </p>
      <p>
        <a href="https://www.eca.ed.ac.uk/profile/beverley-hood">
          https://www.eca.ed.ac.uk/profile/beverley-hood
        </a>
      </p>
      <img src="/ABOUT_image.jpg" />
    </Container>
  </Layout>
);

export default NotFoundPage;

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};
